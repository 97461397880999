import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { ProjectId } from '@/types/interface';
import { memberApi } from '../member/memberAPI';
import { InviteUser, InviteUserToProject, UserDetails } from './interface';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Users', 'ProjectUsers'],
  endpoints: (builder) => ({
    queryUserPhotoBriefByProjectId: builder.query<any, ProjectId>({
      query: ({ ProjectId }) => ({
        url: `/api/user/queryUserPhotoBriefByProjectId`,
        method: 'GET',
        params: { ProjectId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'ProjectUsers', id: arg.ProjectId },
      ],
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.ProjectId !== previousArg?.ProjectId;
      },
      transformResponse: (response) => response.data.data,
    }),
    QueryUserPhotoBriefNotExistInProject: builder.query<any, ProjectId>({
      query: ({ ProjectId }) => ({
        url: `/api/User/QueryUserPhotoBriefNotExistInProject`,
        method: 'GET',
        params: { ProjectId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'ProjectUsers', id: arg.ProjectId },
      ],
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.ProjectId !== previousArg?.ProjectId;
      },
      transformResponse: (response) => response.data.data,
    }),
    SelfProfile: builder.query<any, any>({
      query: ({}) => ({
        url: `/api/user/self`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetInvitationInfo: builder.mutation<any, any>({
      query: (invitationToken) => ({
        url: `/api/user/GetInvitationInfo`,
        method: 'GET',
        params: { invitationToken: invitationToken },
      }),
      transformResponse: (response) => response.data.data,
    }),
    queryUserPhotoBriefByGroupId: builder.query<UserDetails[], string>({
      query: (groupId) => ({
        url: `/api/user/queryUserPhotoBriefByGroupId`,
        method: 'GET',
        params: { groupId },
      }),
      transformResponse: (response) => response.data.data,
    }),

    inviteUser: builder.mutation<any, InviteUser>({
      query: (data) => ({
        url: `/api/user/inviteUser`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(memberApi.util.invalidateTags(['members']));
      },
    }),
    inviteUserToProject: builder.mutation<any, InviteUserToProject>({
      query: (data) => ({
        url: `/api/user/inviteUserToProject`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(memberApi.util.invalidateTags(['members']));
      },
    }),
  }),
});

export const {
  useQueryUserPhotoBriefByProjectIdQuery,
  useQueryUserPhotoBriefNotExistInProjectQuery,
  useSelfProfileQuery,
  useQueryUserPhotoBriefByGroupIdQuery,
  useInviteUserMutation,
  useInviteUserToProjectMutation,
  useGetInvitationInfoMutation,
} = usersApi;

export function register(data) {
  return request({
    url: `/api/user/register`,
    method: 'POST',
    data,
  });
}

export function login(data) {
  return request({
    url: `/api/user/login`,
    method: 'POST',
    data,
  });
}

export function thirdLogin(data) {
  return request({
    url: `/api/user/thirdLogin`,
    method: 'POST',
    data,
  });
}

export function self() {
  return request({
    url: `/api/user/self`,
  });
}

export function logout() {
  return request({
    url: `/api/user/logout`,
    method: 'POST',
  });
}

export function resendVerifyEmail(data) {
  return request({
    url: `/api/user/resendVerifyEmail`,
    method: 'POST',
    data,
  });
}

export function resetPasswordByVerifyToken(data) {
  return request({
    url: `/api/user/resetPasswordByVerifyToken`,
    method: 'POST',
    data,
  });
}

export function verifyEmailByVerifyToken(VerifyToken) {
  return request(
    `/api/user/VerifyEmailByVerifyToken?VerifyToken=${VerifyToken}`,
  );
}

export function requestResetPassword(data) {
  return request({
    url: `/api/user/requestResetPassword`,
    method: 'POST',
    data,
  });
}

export function completeUserProfile(data) {
  return request({
    url: `/api/user/completeUserProfile`,
    method: 'PUT',
    data,
  });
}

export function updateUserPhoto(data) {
  return request(`/api/user/updateUserPhoto`, {
    method: 'PUT',
    data,
  });
}

export function updateUserProfile(data) {
  return request({
    url: `/api/user/UpdateUserProfile`,
    method: 'PUT',
    data,
  });
}

export function queryUserPhotoBriefByProjectId(ProjectId: number) {
  return request({
    url: `/api/user/queryUserPhotoBriefByProjectId?ProjectId=${ProjectId}`,
    method: 'GET',
  });
}
