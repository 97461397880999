import request from '@/utils/request';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/lib/axiosBaseQuery';
import { ProjectId } from '@/types/interface';
import { AxiosResponse } from 'axios';
import {
  AttendanceRecordDetailData,
  AttendanceRecordDetailResponse,
  GetManagerProjectsRequest,
  ManagerProject,
  MemberProductivityData,
  MemberProductivityResponse,
  MemberSelfProductivityData,
  MemberSelfProductivityResponse,
  QueryAttendanceRecordRequestsByPageFilter,
  QueryAttendanceRecordRequestsRequest,
  QueryMemberProjectsWithPendingByFilter,
  QueryMemberProjectsWithPendingByFilterRequest,
  RequestAttendanceRecord,
  SelfTimeSheetData,
  SelfTimeSheetResponse,
  TimeSheetData,
  TimeSheetResponse,
} from './interface';

interface CheckInMembersParams {
  projectId: string;
  date: string;
}

interface CheckInMember {
  attendanceRecordIds: number[];
  userId: number;
  fullName: string;
  email: string;
  photoUrl: string;
  abbreviationBgColor: string;
  nameAbbreviation: string;
  isDelete: boolean;
}

export function queryCheckInMembers(data) {
  return request({
    url: `/api/Member/QueryCheckInMembers`,
    method: 'POST',
    data: data,
  });
}
interface CheckInMembersParmas {
  ProjectId: number;
  Date: string;
}

export const memberApi = createApi({
  reducerPath: 'memberApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['manpowerRecord', 'members'],
  endpoints: (builder) => ({
    getMemberProductivity: builder.query<MemberProductivityData, any>({
      query: (data) => ({
        url: `/api/Member/GetMemberProductivity`,
        method: 'POST',
        data,
      }),
      transformResponse: (
        response: AxiosResponse<MemberProductivityResponse, any>,
      ) => response.data.data,
    }),
    queryTimeSheet: builder.query<TimeSheetData[], any>({
      query: (data) => ({
        url: `/api/Member/QueryTimeSheet`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: AxiosResponse<TimeSheetResponse, any>) => {
        return response.data.data;
      },
      providesTags: ['manpowerRecord'],
    }),
    queryCheckInMembers: builder.query<any, CheckInMembersParmas>({
      query: ({ ProjectId, Date }) => ({
        url: `/api/Member/QueryCheckInMembers`,
        method: 'GET',
        params: { ProjectId, Date },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getAttendanceRecordDetail: builder.query<AttendanceRecordDetailData, any>({
      query: (params) => ({
        url: `/api/Member/GetAttendanceRecordDetail`,
        method: 'GET',
        params: { attendanceRecordId: params },
      }),
      transformResponse: (
        response: AxiosResponse<AttendanceRecordDetailResponse, any>,
      ) => response.data.data,
      providesTags: ['manpowerRecord'],
    }),
    getMemberSelfProductivity: builder.query<MemberSelfProductivityData, any>({
      query: (data) => ({
        url: `/api/Member/GetMemberSelfProductivity`,
        method: 'POST',
        data,
      }),
      transformResponse: (
        response: AxiosResponse<MemberSelfProductivityResponse, any>,
      ) => response.data.data,
    }),
    querySelfTimeSheet: builder.query<SelfTimeSheetData[], any>({
      query: (data) => ({
        url: `/api/Member/QuerySelfTimeSheet`,
        method: 'POST',
        data,
      }),
      transformResponse: (
        response: AxiosResponse<SelfTimeSheetResponse, any>,
      ) => {
        return response.data.data;
      },
    }),
    queryAttendanceRecordRequestsByPageFilter: builder.query<
      QueryAttendanceRecordRequestsByPageFilter,
      QueryAttendanceRecordRequestsRequest
    >({
      query: (data) => ({
        url: `/api/Member/QueryAttendanceRecordRequestsByPageFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    queryAttendanceRecordRequestsByFilter: builder.query<
      RequestAttendanceRecord[],
      QueryAttendanceRecordRequestsRequest
    >({
      query: (data) => ({
        url: `/api/Member/QueryAttendanceRecordRequestsByFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    repliedAttendanceRecordRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/Member/RepliedAttendanceRecordRequest`,
        method: 'POST',
        data,
      }),
    }),
    queryMemberProjectsWithPendingByFilter: builder.query<
      QueryMemberProjectsWithPendingByFilter,
      QueryMemberProjectsWithPendingByFilterRequest
    >({
      query: (data) => ({
        url: `/api/Member/QueryMemberProjectsWithPendingByFilter`,
        method: 'GET',
        params: data,
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['members'],
    }),
    getManagerProjects: builder.query<
      ManagerProject[],
      GetManagerProjectsRequest
    >({
      query: (data) => ({
        url: `/api/Member/GetManagerProjects`,
        method: 'GET',
        params: data,
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useGetMemberProductivityQuery,
  useQueryTimeSheetQuery,
  useQueryCheckInMembersQuery,
  useGetAttendanceRecordDetailQuery,
  useQuerySelfTimeSheetQuery,
  useGetMemberSelfProductivityQuery,
  useQueryAttendanceRecordRequestsByPageFilterQuery,
  useQueryAttendanceRecordRequestsByFilterQuery,
  useQueryMemberProjectsWithPendingByFilterQuery,
  useGetManagerProjectsQuery,
} = memberApi;
